import React from 'react'; 

import { 
  Grid,
  Hidden,
  Typography,
} from '@material-ui/core';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import logo_bw from "../img/logo_bw.png"
//import ts_logo from "../img/techstars_bw.png"

const TOPBAR_HEIGHT = "max(8vh, 80px)"
const SMALL_LOGO_HEIGHT = 40
const LARGE_LOGO_HEIGHT = 60
const SHAPE_INDEX = 0
const WHITE = "#FFFCF3"
const PRIMARY = "#1564bf"
const SECONDARY = "#1c4378"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: PRIMARY,
    background: `linear-gradient(240deg, ${PRIMARY}, ${SECONDARY})`,
    overflowX: "hidden",
    minHeight: "100vh",
  },
  topbar: {
    minHeight: TOPBAR_HEIGHT,
  },
  logo: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  hero: {
    minHeight: `calc(100vh - ${TOPBAR_HEIGHT} - ${TOPBAR_HEIGHT})`,
    padding: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
  logoText: {
    color: WHITE,
    paddingLeft: theme.spacing(4),
    fontSize: "2rem",
    textDecoration: "none",
    cursor: "pointer",
  },
  title: {
    color: WHITE,
    fontSize: "min(max(8vw, 3rem), 5rem)",
    paddingBottom: theme.spacing(1),
  },
  subtitle: {
    color: WHITE,
    fontSize: "min(max(1.7vw, 1.7rem), 2.5rem)",
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  whiteLink: {
    color: WHITE,
  },
  heroText: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    margin: "auto",
  },
  intakeForm: {
    zIndex: SHAPE_INDEX + 1,
    padding: theme.spacing(2),
  },
}));

const Topbar = ({classes}) => (
  <Grid container alignContent="center" justify="space-between" className={classes.topbar}>
    <Hidden xsDown>
      <Grid item sm={6} align="left">
        <img src={logo_bw}  alt="Immuto logo" height={SMALL_LOGO_HEIGHT} className={classes.logo}/>
      </Grid>
    </Hidden>
    <Hidden smUp>
      <Grid item xs={12} align="center">
        <img src={logo_bw}  alt="Immuto logo" height={LARGE_LOGO_HEIGHT} className={classes.logo}/>
      </Grid>
    </Hidden>

  </Grid>
)

const Hero = ({classes, countdown}) => {
  return  <Grid container justify="center" alignContent="center" className={classes.hero}>
    <Grid item xs={12} className={classes.heroText}>
      <Typography className={classes.title} align="center" component="h1">
        Technology for Better Healthcare
      </Typography>

      <Typography className={classes.subtitle} align="center" component="h3">
        Starting with <a href="https://www.tellescope.com" className={classes.whiteLink}> Tellescope</a>, 
        we're helping businesses provide more engaging and secure digital experiences for their 
	patients. <a href="mailto:inquiries@tellescope.com" className={classes.whiteLink}>Contact us here</a> to 
	get in touch.
      </Typography>
    </Grid>

  </Grid>
}

const Homepage = () => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, "gradient-bg")}>
      <Topbar classes={classes}/>
      
      <div className={classes.content}>
        <Hero classes={classes}/>
      </div>

    </div>
  );
}

export default Homepage;
