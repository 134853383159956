import React from 'react'; 

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom"

// see https://www.npmjs.com/package/fontsource-roboto for smaller import instructions
import "fontsource-roboto" // for material-ui fonts
import { makeStyles } from '@material-ui/core/styles';
import { 
  ThemeProvider,
} from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

import Homepage from "./pages/homepage"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#21a1da",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles({
  root: {
    backgroundColor: "#f7f7f7",
    minHeight: "100vh", 
  },
});

const StyleWrapper = ({children}) => {
  const classes = useStyles()

  return <ThemeProvider theme={theme}>
    <div className={classes.root}>
      {children}
    </div>
  </ThemeProvider>
}

function App() {
  return (
    <StyleWrapper>
    <Router>
      <Switch>
        <Route exact path="/">
          <Homepage/>
        </Route>

        <Route>
          <Redirect to="/"/>
        </Route>
      </Switch>
    </Router>
    </StyleWrapper>
  );
}

export default App;
